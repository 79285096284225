$font-primary: "Poppins", Arial, sans-serif;
$primary: #b7d13c;
$primarydark: #8faa11;
$secondary: #b73b3b;
$radius: 16px;
$black: #000;

body {
	font-family: $font-primary;
	font-size: 14px;
	line-height: 1.8;
	font-weight: normal;
	background: #fafafa;
	color: #4e4e4e;
}
.bgprimarydark {
	background-color: $primarydark;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
}
.bgprimary {
	background-color: $primary;
	&:hover {
		background-color: $primarydark;
	}
}
.overflowhid {
	overflow: hidden;
}
a {
	cursor: pointer !important;
	&[data-bs-toggle="collapse"] {
		position: relative !important;
	}

	&.dropdown-toggle {
		white-space: nowrap;
	}
	transition: 0.3s all ease;
	color: $primary;
	text-decoration: none !important;
	&:hover,
	&:focus {
		outline: none !important;
		box-shadow: none;
	}
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: $primary;
}
button {
	transition: 0.3s all ease;
	border-radius: $radius;
	&:hover,
	&:focus {
		text-decoration: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	line-height: 1.5;
	font-weight: 400;
	font-family: $font-primary;
	color: $black;
}

.radius {
	border-radius: $radius !important;
}
.ftco-section {
	padding: 7em 0;
}
.green {
	color: $primarydark;
}
.border-green {
	border-color: $primarydark !important;
}
.ftco-no-pt {
	padding-top: 0;
}
.ftco-no-pb {
	padding-bottom: 0;
}
//HEADING SECTION
.heading-section {
	font-size: 28px;
	color: $black;
	small {
		font-size: 18px;
	}
}

//COVER BG
.img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.navbar {
	padding: 15px 10px;
	background: #fff;
	border: none;
	border-radius: 0;
	margin-bottom: 40px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
	box-shadow: none;
	outline: none !important;
	border: none;
}

.line {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #ddd;
	margin: 40px 0;
}
.invalid-tooltip {
	transition: all 0.6s ease-in-out;
	border-radius: 16px;
	font-size: 12px;
	top: 19px;
	left: 2px;
	padding: 0.25rem 0.5rem 0.21rem;
}
.hideit {
	opacity: 0;
}
//SIDEBAR
.wrapper {
	width: 100%;
	background-color: #f5f5f5;
}
.row {
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) / -2);
	margin-left: calc(var(--bs-gutter-x) / -2);
	* {
		// max-width: 100%;
		// padding-right: calc(var(--bs-gutter-x) * .5);
		// padding-left: calc(var(--bs-gutter-x) * .5);
		// margin-top: var(--bs-gutter-y);
	}
}
.card {
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
	background: #fff;
	border-radius: $radius;
	overflow: hidden;
	border: initial;
}
#sidebar {
	min-width: 300px;
	max-width: 300px;
	min-height: 100vh;
	height: 100vh;
	position: fixed;
	background: $primarydark;
	color: #fff;
	border-radius: 0 25px 25px 0;
	transition: all 0.3s;
	&.active {
		margin-left: -300px;
	}
	.logo {
		display: block;
		width: 50px;
		height: auto;
		margin: 0 auto;
		span {
			display: block;
		}
	}
	ul.components {
		padding: 0;
	}
	ul {
		li {
			font-size: 14px;
			border-radius: $radius;
			> ul {
				margin-left: 0px;
				padding-left: 20px;
				li {
					font-size: 13px;
					border-radius: $radius;
					margin-top: 10px;
					padding: 0;
					.badge {
						border-radius: 70% 15%;
						padding: 7px;
						margin-left: 10px;
						width: 24px;
						height: 24px;
						position: relative;
						top: -1px;
						font-size: 11px;
						background-color: #fff !important;
						color: $primarydark;
					}
				}
			}
			padding: 0px 30px;
			margin-top: 10px;
			a {
				&[aria-expanded="true"] {
					background-color: rgba(255, 255, 255, 0.2);
				}
				padding: 10px 30px;
				display: block;
				color: rgba(255, 255, 255, 0.8);
				border-radius: $radius;
				&:hover {
					color: #fff;
					background-color: rgba(255, 255, 255, 0.2);
					border-radius: $radius;
				}
				svg {
					margin-right: 8px;
					margin-bottom: 2px;
				}
			}
			&.active {
				> a {
					background-color: rgba(255, 255, 255, 0.2);
					color: #fff;
				}
			}
		}
	}
	select {
		appearance: none;
	}
}
.shop-select {
	padding: 10px 20px;
	border: 0;
	background-color: $primary;
	border-radius: $radius;
	color: #fff;
	option {
		color: #000;
		padding: 10px;
	}

	appearance: none;
}
.font-light {
	font-weight: 200;
}
.customer {
}
.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 42%;
	right: 20px;
	transform: translateY(-50%);
}

#content {
	width: 100%;
	margin-left: 300px;
	padding: 0;
	min-height: 100vh;
	transition: all 0.3s;
}

.logo-leaf-heading {
	max-height: 35px;
	position: relative;
	top: -8px;
}
.bg-outline-yellow {
	border: 1px solid #e0b909;
	color: #e0b909;
}
.btn {
	border-radius: $radius;
	&.btn-primary {
		background: $primary;
		border-color: $primary;
		&:hover,
		&:focus {
			background: $primary !important;
			border-color: $primary !important;
		}
	}
	&:hover img {
		filter: brightness(5) grayscale(2);
	}
	.btn-white {
		color: #7c7c7c;
		background-color: #fff;
	}
	&.btn-outline-primary {
		border-color: $primary;
		color: $primarydark;
		&:hover {
			color: #fff;
			background-color: $primary;
		}
	}
}

// produkty

#title {
	font-size: 1.85em;
	line-height: 1;
	font-weight: 300;
}
.muted-link {
	color: #afafaf;
}
.text-small {
	font-size: 0.7em !important;
	font-weight: initial !important;
}
.search-input-container {
	position: relative;
	border-radius: $radius;
}
.shadow {
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
}
.search-input-container input {
	width: calc(100% - 25px);
	border: initial;
}

.search-input-container .search-magnifier-icon,
.search-input-container .search-magnifier-icon img {
	position: absolute;
	right: 5px;
	top: 0;
	height: 100%;
	width: 20px;
	cursor: pointer;
}
.form-switch .form-check-input {
	width: 3em;
	margin-right: 15px;
	transition: 0.3s all ease-in-out;
	border: 1px solid rgba(0, 0, 0, 0.25) !important;
	&:checked {
		background-color: $primary;
		border-color: $primarydark;
	}
	&:not(:checked) {
		filter: grayscale(2);
	}
}
#customer-details {
	.card {
		.shopselector {
			margin-top: 15px;
		}
	}
}
.custom-select,
.form-control,
.form-select {
	font-size: 1em;
	width: auto;
	height: auto;
	min-height: var(--input-height);
	color: #000;
	box-shadow: initial;
	background-color: #fff;
	border-color: #eee;
	border-radius: $radius !important;
	filter: none;
	transition: border-color 0.15s ease-in-out;
	&:focus {
		border-color: $primarydark !important;
	}
}

.dropdown-toggle::after,
.dropend .dropdown-toggle::after,
.dropstart .dropdown-toggle::before,
.dropup .dropdown-toggle::after {
	width: 5px;
	height: 5px;
	border: initial;
	transform: rotate(45deg);
	border-top: 1px solid;
	border-right: 1px solid;
	margin-top: initial;
	vertical-align: middle;
	margin-bottom: 2px;
}

#product-list,
#inwentaryzacja-list {
	.card {
		.card-img {
			border-radius: $radius 0 0 $radius;
			width: 5.5rem !important;
			object-fit: cover;
			max-height: 65px;
		}
	}
}
#product-details {
	.ingredients-table {
		.btn {
			border-radius: 0 0 $radius $radius !important;
		}
		.delete-ingredient {
			button {
				border-radius: $radius !important;
			}
		}
	}
}
#inwentaryzacja-list {
	.product {
		// background: rgba(255,255,255, 0.2);
		&:hover {
			background: rgba(255, 255, 255, 1);
		}
	}
}

input,
textarea {
	border: 1px solid #eeeeee;
	box-sizing: border-box;
	margin: 0;
	outline: none;
	padding: 10px;
	&:focus {
		border-color: transparent !important;
		box-shadow: none !important;
	}
}
.product-history {
	padding-left: 20px;
	border-radius: 0;
	.card {
		border-radius: 0;
		border-top: 1px solid #eee;
	}
	.history-record {
		padding: 5px 40px 5px 16px;
		margin: 0 -16px;
		width: calc(100% + 32px);
		border-bottom: 1px dashed #eee;
		border-radius: $radius;
		&:hover {
			background-color: #eee;
		}
		* {
			display: flex;
			align-content: center;
		}
	}
}

.dailysales {
	padding-top: 10px;
	padding-bottom: 10px;
	.btn {
		* {
			transition: all 0.5s ease-in-out;
		}
		&[aria-expanded="false"] {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}
.logoleaf {
	border-radius: 70% 15%;
	display: inline-block;
	padding: 7px;
	width: 14px;
	height: 14px;
	margin-right: 10px;
	position: relative;
	top: 1px;
	font-size: 11px;
	background-color: #fff !important;
	color: $primarydark;
}
.card.transaction,
.cursor-pointer {
	cursor: pointer;
}
.nav-pills {
	.nav-link {
		border-radius: 50px;
		color: $primarydark;
		&.active {
			background-color: $primary;
			.badge {
				background-color: #fff !important;
				color: $primary;
			}
		}
		.badge {
			border-radius: 70% 15%;
			padding: 7px;
			margin-left: 10px;
			width: 24px;
			height: 24px;
			position: relative;
			top: -1px;
			font-size: 11px;
			background-color: $primary !important;
			color: #fff;
		}
	}
}

.semigreen {
	background-color: rgba(142, 170, 17, 0.2);
}
.semired {
	background-color: rgba(183, 59, 59, 0.2);
}
.bg-outline-green {
	box-shadow: inset 0 0 0 1px $primary !important;
	color: $primarydark !important;
}

.bg-outline-red {
	box-shadow: inset 0 0 0 1px $secondary !important;
	color: $secondary !important;
}
.form-select {
	&:focus {
		border-color: $primary !important;
		box-shadow: none !important;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
.w-auto {
	width: auto;
}
.input-group .button-minus,
.input-group .button-plus {
	font-weight: bold;
	height: 38px;
	padding: 0;
	width: 38px;
	border-radius: $radius;
	position: relative;
	&:hover {
		background-color: $primary;
		color: #fff;
	}
}

.input-group .quantity-field {
	border-radius: $radius;
	position: relative;
	height: 38px;
	left: -6px;
	text-align: center;
	max-width: 40px;
	width: auto;
	display: inline-block;
	font-size: 13px;
	margin: 0 0 5px;
	resize: vertical;
}

.admin-shop {
	padding-left: 20px;
	&:hover {
		background-color: #eee;
		border-radius: $radius;
	}
}
.breadcrumb-back,
.breadcrumb-back a,
.breadcrumb-back a span {
	font-size: 150% !important;
}
.button-plus {
	left: -8px;
}

input[type="number"] {
	-moz-appearance: textfield;
	-webkit-appearance: none;
}

.empty-list {
	img {
		max-height: 300px;
		opacity: 0.5;
		filter: grayscale(1);
	}

	h4 {
		// opacity: .;
	}
}
.menu-submenu-ico {
	filter: brightness(5);
	transition: all 0.5s ease-in-out;
}

nav {
	.list-unstyled {
		a[aria-expanded="true"] {
			.menu-submenu-ico {
				transform: rotate(180deg);
			}
		}
	}
}
.toastie {
	position: fixed;
	right: 70px;
	bottom: 70px;
	z-index: 999;
	min-width: 300px;
	.card {
		h5 {
			text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
			font-weight: 800;
			color: #fff;
		}
		p {
			text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
			margin: 0;
		}
		a {
			font-weight: 800;
			color: #fff;
		}
		border-radius: $radius;
		background-color: $primary;
		color: #fff;
	}
	&.alert {
		.card {
			padding-left: 10px;
			h5 {
				text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
				font-weight: 800;
				color: $secondary;
				position: relative;
				&:before {
					display: block;
					content: "";
					background-color: $secondary;
					width: 15px;
					height: 100px;
					position: absolute;
					left: -26px;
					top: -16px;
				}
			}
			p {
				text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
				margin: 0;
			}
			a {
				font-weight: 800;
				color: #282828;
			}
			border-radius: $radius;
			background-color: #fff;
			// border-left: 5px solid $secondary;
			color: #0f0c0c;
		}
	}
	&.success {
		.card {
			padding-left: 10px;
			h5 {
				text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
				font-weight: 800;
				color: $primarydark;
				position: relative;
				&:before {
					display: block;
					content: "";
					background-color: $primarydark;
					width: 15px;
					height: 100px;
					position: absolute;
					left: -26px;
					top: -16px;
				}
			}
			p {
				text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
				margin: 0;
			}
			a {
				font-weight: 800;
				color: #282828;
			}
			border-radius: $radius;
			background-color: #fff;
			color: #0f0c0c;
		}
	}
}

.page-item {
	.page-link {
		outline: initial !important;
		box-shadow: initial !important;
		border: initial;
		padding: 8px 9px;
		line-height: 1.5;
		font-size: 1em;
		width: 40px;
		height: 40px;
		color: $primary;
		border-radius: $radius !important;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.disabled .page-link {
		opacity: 0.5;
		color: $primary;
		background: transparent;
		box-shadow: initial !important;
		pointer-events: none;
		background-color: transparent;
		border-color: #dee2e6;
	}
	&.active .page-link {
		background: $primary;
		box-shadow: none;
		color: #fff;
	}
}

// logowanie
.login-container {
	min-height: 100vh;
	display: flex;
	align-content: center;
	flex-direction: column;
	justify-content: center;

	.logo {
		justify-content: center;
		align-items: center;
		display: flex;
		margin-bottom: 22px;

		svg {
			width: 100px;
		}
	}
	input {
		border: 1px solid #eee;
		&:focus {
			border: 1px solid $primary !important;
		}
	}
}
.footer {
	li {
		padding: 10px 30px;
	}
	p {
		color: rgba(255, 255, 255, 0.5);
	}
}

//dropzone

.dropzone {
	min-height: 25px !important;

	padding: 0 0 30px 0 !important;
	border: 0 !important;
	color: #000 !important;
	height: auto;
}
.dropzone .img-thumbnail {
	height: 58px;
	max-height: 125px;
	min-height: 125px;
	width: 100% !important;
	object-fit: contain !important;
	padding: initial;
	width: 100%;
	height: 100%;
	filter: initial !important;
	transform: initial !important;
	border-radius: $radius;
	border-top-right-radius: initial;
	border-bottom-right-radius: initial;
	background-color: unset !important;
}
.dropzone .image-container {
	width: 25%;
}
.dropzone:hover .dz-message {
	color: $primary !important;
}

.dropzone.dz-clickable .dz-message {
	position: absolute;
	margin: 0 auto;
	// top: 50%;
	bottom: 5px;
	// left: 50%;
	width: 95%;
	display: flex;
	justify-content: center;
	// transform: translate(-50%, -50%);
	color: #000;
}

.dropzone.dz-clickable .dz-message span {
	top: 50px !important;
	text-align: center;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
	max-width: 100%;
	min-height: unset;
	border-radius: $radius !important;
	color: #000 !important;
	margin-left: initial !important;
	margin-top: initial !important;
}
.dropzone .dz-preview.dz-image-preview > div,
.dropzone .dz-preview.dz-file-preview > div {
	position: relative;
	border-radius: $radius;
	overflow: hidden;
}
.dropzone .dz-preview.dz-image-preview .dz-image,
.dropzone .dz-preview.dz-file-preview .dz-image {
	height: 100%;
	width: 80px;
	float: left;
	border-radius: initial;
}
.dropzone .dz-preview.dz-image-preview .dz-image img,
.dropzone .dz-preview.dz-file-preview .dz-image img {
	width: 100%;
}
.dropzone .dz-preview.dz-image-preview .preview-container,
.dropzone .dz-preview.dz-file-preview .preview-container {
	transition: initial !important;
	animation: initial !important;
	margin-left: 0;
	margin-top: 0;
	position: relative;
	width: 100%;
	height: 100%;
}
.dropzone .dz-preview.dz-image-preview .preview-container i,
.dropzone .dz-preview.dz-file-preview .preview-container i {
	color: $primary;
	font-size: 20px;
	position: absolute;
	left: 50%;
	top: 29px;
	transform: translateX(-50%) translateY(-50%) !important;
	height: 22px;
}
.dropzone .dz-preview.dz-image-preview strong,
.dropzone .dz-preview.dz-file-preview strong {
	font-weight: normal;
}
.dropzone .dz-preview.dz-image-preview .remove,
.dropzone .dz-preview.dz-file-preview .remove {
	position: absolute;
	right: 8px;
	bottom: 8px;
	color: #555 !important;
}
.dropzone .dz-preview.dz-image-preview .remove img,
.dropzone .dz-preview.dz-file-preview .remove img {
	cursor: pointer;
}
.dropzone .dz-preview.dz-image-preview .remove:hover,
.dropzone .dz-preview.dz-file-preview .remove:hover {
	color: $primary !important;
}
.dropzone .dz-preview.dz-image-preview .dz-details,
.dropzone .dz-preview.dz-file-preview .dz-details {
	position: static;
	display: block;
	opacity: 1;
	text-align: left;
	min-width: unset;
	z-index: initial;
	color: #000 !important;
	float: left;
	padding: 0.75rem 1rem;
	width: 75%;
}
.dropzone .dz-preview.dz-image-preview .dz-details .dz-size,
.dropzone .dz-preview.dz-file-preview .dz-details .dz-size {
	margin-bottom: 0;
	font-size: 1em;
}
.dropzone .dz-preview.dz-image-preview .dz-details .dz-filename span,
.dropzone .dz-preview.dz-file-preview .dz-details .dz-filename span {
	border: initial !important;
	background: transparent !important;
}
.dropzone .dz-preview.dz-image-preview .dz-error-mark,
.dropzone .dz-preview.dz-image-preview .dz-success-mark,
.dropzone .dz-preview.dz-file-preview .dz-error-mark,
.dropzone .dz-preview.dz-file-preview .dz-success-mark {
	color: $primary !important;
	margin-left: 0;
	margin-top: 0;
	bottom: initial;
	right: initial;
	top: 13px;
	left: calc(45% - 12px);
	padding: 7px 8px;
	border-radius: $radius;
	line-height: 1;
}
.dropzone .dz-preview.dz-image-preview .dz-error-mark img,
.dropzone .dz-preview.dz-image-preview .dz-success-mark img,
.dropzone .dz-preview.dz-file-preview .dz-error-mark img,
.dropzone .dz-preview.dz-file-preview .dz-success-mark img {
	font-size: 18px !important;
	margin: 0 auto;
	display: block;
	color: $primary !important;
}
.dropzone .dz-preview.dz-image-preview .dz-error-mark img,
.dropzone .dz-preview.dz-file-preview .dz-error-mark img {
	color: $primary !important;
}
.dropzone .dz-preview.dz-image-preview .dz-progress,
.dropzone .dz-preview.dz-file-preview .dz-progress {
	width: 100%;
	margin-left: 0;
	margin-top: 0;
	right: 0;
	height: 2px !important;
	left: 15px;
	margin-top: 5px;
	position: static;
}
.dropzone .dz-preview.dz-image-preview .dz-progress .dz-upload,
.dropzone .dz-preview.dz-file-preview .dz-progress .dz-upload {
	width: 100%;
	background: $primary !important;
}
.dropzone .dz-preview.dz-image-preview .dz-error-message,
.dropzone .dz-preview.dz-file-preview .dz-error-message {
	top: -120px;
	z-index: 9999;
	left: 30%;
	color: #fff;
	padding: 10px;
	border-radius: $radius;
	font-size: 0.875em;
	display: block;
	background-color: $secondary;
}

.dropzone .dz-preview.dz-image-preview .dz-error-message:after,
.dropzone .dz-preview.dz-file-preview .dz-error-message:after {
	border-bottom: 6px solid $secondary !important;
}
.dropzone .dz-preview.dz-image-preview .dz-error-message:before,
.dropzone .dz-preview.dz-file-preview .dz-error-message:before {
	content: " ";
	position: absolute;
	top: -5px;
	left: 64px;
	z-index: 1;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}
.dropzone .dz-preview.dz-image-preview [data-dz-name],
.dropzone .dz-preview.dz-file-preview [data-dz-name] {
	white-space: nowrap;
	text-overflow: ellipsis;
	width: calc(100% - 35px);
	display: inline-block;
	overflow: hidden;
}

.dropzone.dropzone-columns {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
.dropzone.dropzone-columns .dz-preview.dz-image-preview,
.dropzone.dropzone-columns .dz-preview.dz-file-preview {
	margin-top: var(--bs-gutter-y) !important;
	margin-bottom: initial !important;
}

.dropzone.dropzone-columns .dz-preview.dz-image-preview,
.dropzone.dropzone-columns .dz-preview.dz-file-preview {
	width: calc(50% - 5px);
	flex: 0 0 calc(50% - 5px);
}

.dropzone:not(.dropzone-columns) .dz-preview.dz-image-preview,
.dropzone:not(.dropzone-columns) .dz-preview.dz-file-preview {
	width: 100%;
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
	display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview .preview-icon {
	display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview .dz-error-mark,
.dropzone .dz-error.dz-preview.dz-file-preview .dz-success-mark {
	color: $primary !important;
	right: 8px;
	left: initial;
	top: initial;
	bottom: 3px;
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
	display: none;
}

.dropzone.dz-drag-hover {
	border-color: $primary !important;
}
.dropzone.dz-drag-hover .dz-message {
	color: $primary !important;
	opacity: 1;
}

.dropzone.dropzone-top-label {
	padding: 2rem 0.5rem 0rem 1rem !important;
	min-height: 103px !important;
}

.form-floating .dropzone.dropzone-floating-label {
	padding: 1rem !important;
	min-height: 101px !important;
}

.form-floating .dropzone.dropzone-floating-label.dz-started {
	padding-top: 2rem !important;
	padding-bottom: 0 !important;
}

.form-floating .dropzone.dropzone-floating-label.dz-started ~ label {
	-webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
	color: #555;
}

.dropzone.dropzone-filled {
	border: 1px solid transparent !important;
	background: #f5f5f5 !important;
	padding-left: 45px !important;
}
.dropzone.dropzone-filled .dz-message {
	top: initial;
	left: 45px;
	transform: initial;
	color: #555 !important;
	font-weight: 300;
	top: 11px;
}
.dropzone.dropzone-filled + i {
	margin-top: 0;
	top: 14px;
}
.dropzone.dropzone-filled.dropzone.dz-drag-hover {
	border-color: $primary !important;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
	animation: initial;
}

.dropzone.row {
	min-height: 210px;
}
.dropzone.row.border-0 {
	border: initial !important;
}
.dropzone.row.p-0 {
	padding: initial !important;
}
.dropzone.row .dz-preview.dz-image-preview.col.border-0,
.dropzone.row .dz-preview.dz-file-preview.col.border-0 {
	border: initial !important;
}
.dropzone.row .dz-preview.dz-image-preview .dz-error-mark,
.dropzone.row .dz-preview.dz-image-preview .dz-success-mark,
.dropzone.row .dz-preview.dz-file-preview .dz-error-mark,
.dropzone.row .dz-preview.dz-file-preview .dz-success-mark {
	left: -16px;
	margin-left: 50%;
	top: 20px;
	margin-top: 0;
}
.dropzone.row .dz-preview.dz-image-preview .remove,
.dropzone.row .dz-preview.dz-file-preview .remove {
	bottom: 25px;
	top: initial;
	right: 20px;
	left: initial;
}
.dropzone.row .dz-preview.dz-image-preview .dz-error-message,
.dropzone.row .dz-preview.dz-file-preview .dz-error-message {
	left: 50%;
	right: initial;
	transform: translateX(-50%);
}

// app loader
#app-loader {
	position: absolute;
	width: 100%;
	height: 100%;
	background: white;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 100;
}

#app-loader .icon-wrapper {
	width: 5%;
}

// global message for all forms
.custom-form-error {
	color: #b73b3b;
	font-size: 13px;
	margin-bottom: 10px;
}

.order-product-thumbnail {
	max-height: 60px !important;
	object-fit: contain;
}
